<p-toast></p-toast>
<p-blockUI [blocked]="progressService.blocked | async">
    <p-progressSpinner aria-label="Loading"></p-progressSpinner><br/>
    <div style="font-size: 3rem">{{progressService.progressMessage | async}}</div>
</p-blockUI>
<div class="grid">
    <div class="col-12">
        <div class="card">
            <h4>Loading</h4>
            <p>Please wait</p>
        </div>
    </div>
</div>
