import {Component, inject, OnDestroy} from '@angular/core';
import {DataService} from "../../service/data.service";
import {CanActivateFn, Router} from "@angular/router";
import {Subscription, take} from "rxjs";
import {ProgressService} from "../../service/progress.service";

@Component({
  templateUrl: './loading-page.component.html',
})
export class LoadingPageComponent implements OnDestroy {
    constructor(protected progressService: ProgressService, private dataService: DataService) {
        progressService.blockWithMessage('Loading . . .');
        this.subscriptions.push(dataService.user.subscribe(u => {
            progressService.unBlock();
            this.dataService.redirectToHome(true);
        }));
    }

    private subscriptions: Subscription[] = [];
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    }
}

export const initialLoadingGuard: CanActivateFn = (route, state) => {
    // console.log("Running Initial loading check");
    const dataService = inject(DataService);
    if (dataService.started()) {
        return true;
    } else {
        dataService.redirectUrl=state.url;
        dataService.rehydrate(route.queryParams['session']);
        return inject(Router).parseUrl("/loading");
    }
};
